import React from "react";

import { COLORS } from "../component.constants";
import CloseIcon from "../icons/close-icon";
import styles from "./errorMessage.module.scss";

type Props = {
    message: string;
    onClose: (event) => void;
};

const ErrorMessage: React.FC<Props> = ({ message, onClose }) => {
    const closeIconProps = {
        color: COLORS.white,
        width: 16,
        height: 16,
        viewBoxWidth: 24,
        viewBoxHeight: 24,
    };
    return (
        <div className={styles.banner}>
            <div className={styles.message}>{message}</div>
            <div className={styles.iconContainer} onClick={onClose}>
                <CloseIcon {...closeIconProps} />
            </div>
        </div>
    );
};

export default ErrorMessage;
