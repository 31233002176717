import { Modal, Dropdown } from "@afterpaytouch/core";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import React from "react";

import { useAuthLogoffUser } from "@/context/AuthContext";
import { useCurrentLocale } from "@/context/LanguageContext";

import { menuLinkHREF, dropdownItems } from "@/components/navigation/auth/auth";

import { DropdownItem } from "@/types/auth";

import { KINDS, SIZES } from "../../buttons/buttons.constants";
import LinkButton from "../../buttons/link-button";
import styles from "./menu-modal.module.scss";

export interface Props {
    show: boolean;
    onClose: () => void;
}

export const MenuModal: React.FC<Props> = ({ show, onClose }) => {
    const { t } = useTranslation();
    const locale = useCurrentLocale();
    const logoffUser = useAuthLogoffUser();

    const renderDropdownMenuItem = (item: DropdownItem, i: number): React.ReactNode => {
        const href = menuLinkHREF(item.type, locale);
        const ddMenuItem = (
            <Dropdown.MenuItem
                onClick={item.type === "logout" ? logoffUser : undefined}
                value={item.type}
                key={`${item.type}-${i}`}
                selected={item.type === "shop"}
            >
                <LinkButton
                    size={SIZES.UNSET}
                    kind={KINDS.UNSET}
                    label={t(item.translationKey)}
                    labelElement={item.icon}
                />
            </Dropdown.MenuItem>
        );
        if (!href) {
            return ddMenuItem;
        }
        return (
            <Link href={href}>
                <a>{ddMenuItem}</a>
            </Link>
        );
    };

    return (
        <Modal
            show={show}
            onClose={() => onClose()}
            onBackdropClick={() => onClose()}
            noPadding={true}
        >
            <Modal.Content>
                <div className={styles.menuModal}>
                    {dropdownItems.map((item, i) => {
                        return renderDropdownMenuItem(item, i);
                    })}
                </div>
            </Modal.Content>
        </Modal>
    );
};
