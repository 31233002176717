import classNames from "classnames/bind";
import React, { useState } from "react";

import { UI_CONTEXTS } from "../../../analytics/constants";
import { GenericNavItem } from "../../../interfaces/navigation-interface";
import { NAVIGATION_ITEM_COLORS } from "../../../shared/constants";
import { KINDS, SIZES } from "../../buttons/buttons.constants";
import LinkButton from "../../buttons/link-button";
import styles from "../mobileNavigation.module.scss";

const cx = classNames.bind(styles);

interface Props {
    url: string;
    text: string;
    subMenuItemChunks?: Array<Array<GenericNavItem>>;
}

const linkButtonProps = {
    kind: KINDS.UNSET,
    size: SIZES.UNSET,
    overrideFocusBorder: false,
};

const renderSubmenu = (subMenuItemChunks: GenericNavItem[][]) => {
    return subMenuItemChunks.map((chunk, index) => (
        <ul className={styles.mobileNavigationSubMenu} key={`submenu-${index}`}>
            {chunk.map(({ id, text, link, bold, color }) => {
                const mobileNavigationItemClassName = cx({
                    [styles.mobileNavigationSubMenuItem]: true,
                    [styles.mobileNavigationSubMenuItemBold]: bold,
                    [styles.mobileNavigationSubMenuItemBlack]:
                        color?.name === NAVIGATION_ITEM_COLORS.BLACK,
                    [styles.mobileNavigationSubMenuItemFire]:
                        color?.name === NAVIGATION_ITEM_COLORS.FIRE,
                    [styles.mobileNavigationSubMenuItemOrca]:
                        color?.name === NAVIGATION_ITEM_COLORS.ORCA,
                });

                if (!link) {
                    return (
                        <li className={mobileNavigationItemClassName} key={id}>
                            {text}
                        </li>
                    );
                }

                return (
                    <li className={mobileNavigationItemClassName} key={id}>
                        <LinkButton {...linkButtonProps} label={text} url={link} />
                    </li>
                );
            })}
        </ul>
    ));
};

export const MobileNavigationItem: React.FC<Props> = (props: Props) => {
    const { text, subMenuItemChunks, url } = props;
    const [isMenuItemActive, setIsMenuItemActive] = useState(false);

    const mobileNavigationItemClassName = cx({
        [styles.mobileNavigationItem]: true,
    });

    const mobileNavigationChevronClassName = cx({
        [styles.mobileNavigationChevron]: subMenuItemChunks.length,
        [styles.mobileNavigationChevronActive]: isMenuItemActive,
    });

    const mobileNavigationSubMenuContainerClassName = cx({
        [styles.mobileNavigationSubMenuContainer]: true,
    });

    const onClick = () => {
        setIsMenuItemActive(!isMenuItemActive);
    };

    return (
        <>
            <li className={mobileNavigationItemClassName}>
                <LinkButton
                    {...linkButtonProps}
                    url={url}
                    label={text}
                    uiContext={UI_CONTEXTS.TOP_NAVIGATION}
                />
                <div className={styles.mobileNavigationChevronContainer} onClick={onClick}>
                    <div className={mobileNavigationChevronClassName} />
                </div>
            </li>
            <div className={mobileNavigationSubMenuContainerClassName}>
                {isMenuItemActive && renderSubmenu(subMenuItemChunks)}
            </div>
        </>
    );
};
