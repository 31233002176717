import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useCurrentLocale } from "@/context/LanguageContext";

import { UI_CONTEXTS } from "../../../analytics/constants";
import EVENTS from "../../../analytics/events";
import ProfileIcon from "../../../assets/icons/profile-icon.svg";
import { isFrites } from "../../../shared/helpers/locations-helper";
import { KINDS, SIZES } from "../../buttons/buttons.constants";
import LinkButton from "../../buttons/link-button";
import styles from "../navigation.module.scss";
import { getPortalURL, getBranchPortalURL } from "./auth";

export const AuthUnauthenticated: React.FC<Record<string, never>> = () => {
    const { t } = useTranslation();
    const locale = useCurrentLocale();

    // param for redirecting back from portal after login or sign up
    const redirectShopUrlParam =
        typeof window !== "undefined" ? "&redirectShopUrl=" + window.location.href : "";

    const loginURL = useMemo<string>(() => {
        const portalBaseURL = getPortalURL(locale);
        const portalLoginURLParams = t("header.login.link");
        return `${portalBaseURL}${portalLoginURLParams}${redirectShopUrlParam}`;
    }, [locale, t, redirectShopUrlParam]);

    const signUpURL = useMemo<string>(() => {
        const portalBaseURL = getBranchPortalURL(locale);
        const portalSignUpURLParams = t("header.signup.link");

        return `${portalBaseURL}${portalSignUpURLParams}${redirectShopUrlParam}`;
    }, [locale, t, redirectShopUrlParam]);

    const isSignUpButtonVisible = useMemo(() => !isFrites(locale), [locale]);

    return (
        <div className={styles.ctaContainer}>
            <div className={styles.buttonContainer}>
                <LinkButton
                    fluid={false}
                    kind={KINDS.ICON}
                    size={SIZES.UNSET}
                    disabled={false}
                    show={true}
                    overrideFocusBorder={false}
                    labelElement={
                        <>
                            <ProfileIcon className={styles.loginButtonIcon} />
                            <span className={styles.loginButtonLabel}>
                                {t("header.login.text")}
                            </span>
                        </>
                    }
                    newTab={false}
                    url={loginURL}
                    analytics={{
                        eventName: EVENTS.BUTTON_CLICK,
                        uiContext: UI_CONTEXTS.TOP_NAVIGATION,
                        eventProps: {
                            title: t("header.login.text"),
                        },
                    }}
                />
            </div>
            {isSignUpButtonVisible && (
                <div className={styles.buttonContainer}>
                    <LinkButton
                        label={t("header.signup.text")}
                        fluid={false}
                        kind={KINDS.NAVIGATION}
                        size={SIZES.UNSET}
                        disabled={false}
                        show={true}
                        overrideFocusBorder={false}
                        newTab={false}
                        url={signUpURL}
                        analytics={{
                            eventName: EVENTS.BUTTON_CLICK,
                            uiContext: UI_CONTEXTS.TOP_NAVIGATION,
                        }}
                    />
                </div>
            )}
        </div>
    );
};
