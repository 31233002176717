import { useRouter } from "next/router";
import { useCallback, useEffect } from "react";

import { useFeatureFlags } from "@/hooks/useFeatureFlags";

import { useCurrentLocale } from "../context/LanguageContext";
import { FRITES_LOCALES } from "../shared/constants";
import { isFrites } from "../shared/helpers/locations-helper";

export const useClientRedirections = () => {
    const locale = useCurrentLocale();
    const router = useRouter();
    const { fritesRedirectionsList } = useFeatureFlags();
    const pathname = router.asPath;

    const isInBlackList = useCallback(
        (url: string) => {
            return (
                fritesRedirectionsList?.enable &&
                fritesRedirectionsList?.values.some((rule) => url.includes(rule))
            );
        },
        [fritesRedirectionsList]
    );

    useEffect(() => {
        const onRouteChange = (url: string) => {
            const isFritesCountry = FRITES_LOCALES.some((friteLocale) => url.includes(friteLocale));

            if (isInBlackList(url) && isFritesCountry) {
                throw "Abort route change due to FRITES limitations";
            }
        };
        router.events.on("routeChangeStart", onRouteChange);
        return () => {
            router.events.off("routeChangeStart", onRouteChange);
        };
    }, [fritesRedirectionsList]);

    useEffect(() => {
        const isFritesCountry = isFrites(locale);

        if (pathname.length > 1 && isFritesCountry && isInBlackList(pathname)) {
            router.push(`/${locale}`);
        }
    }, [pathname, locale, fritesRedirectionsList]);
};
