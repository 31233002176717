import { useDebounce } from "@react-hook/debounce";
import classNames from "classnames/bind";
import Cookies from "js-cookie";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import React, { ChangeEvent, FocusEvent, KeyboardEvent, useEffect, useState } from "react";
import Lottie from "react-lottie-player";
import { v4 as uuidV4 } from "uuid";

import { LOCALES_WITH_NO_SEARCH, SEARCH_PAGE_COR_ID_COOKIE_KEY } from "@/shared/constants";

import { parallel } from "@/utils/promises";

import { AMPLITUDE_SEARCH_VERSIONS } from "@/analytics/constants";
import { UI_CONTEXTS } from "@/analytics/constants";

import { fetchCategories } from "@/pages/api/store-directory/categories";
import { fetchSuggestions } from "@/pages/api/store-directory/suggestions";

import { useCurrentLocale } from "@/context/LanguageContext";

import { CategoryProps } from "@/interfaces/category-interface";
import { SuggestionProps } from "@/interfaces/stores-interface";

import EVENTS from "../../analytics/events";
import LogoAnimation from "../../assets/animations/mint-logo-transparent-bg.json";
import { KINDS as BUTTON_KINDS, SIZES as BUTTON_SIZES } from "../buttons/buttons.constants";
import LinkButton from "../buttons/link-button";
import { SIZES as INPUT_SIZES, TYPES } from "../inputs/input.constants";
import { SearchInput } from "../inputs/search-input/search-input";
import { OptionListNoResults } from "./search-bar-no-results";
import { SuggestionHeading } from "./search-bar-suggestion-heading";
import {
    SEARCH_VERSION,
    CATEGORY_SUGGESTION_START_INDEX,
    QUERY_SUGGESTIONS_RESULTS_LIMIT,
} from "./search-bar.constants";
import styles from "./search-bar.module.scss";

const cx = classNames.bind(styles);

const highlightSuggestions = (name, highlightString) => {
    const splitStoreName = name.split(highlightString);

    if (splitStoreName.length === 1) return name;

    return splitStoreName
        .flatMap((split, index) => {
            return [
                split,
                <span key={`${name}-${index}`} className={styles.storeNameHighlighted}>
                    {highlightString}
                </span>,
            ];
        })
        .slice(0, -1);
};

interface SuggestionListProps {
    data: { name: string; id: string };
    searchUrlLink: string;
    highlightString?: string;
    activeSuggestedResult: number;
    index: number;
    searchTerm: string;
    httpCorrelationId: string;
    resultsCount: number;
}
const SuggestionList: React.FC<SuggestionListProps> = ({
    data,
    searchUrlLink,
    highlightString,
    activeSuggestedResult,
    index,
    searchTerm,
    httpCorrelationId,
    resultsCount,
}) => {
    const suggestionButtonKind =
        index === activeSuggestedResult ? BUTTON_KINDS.SEARCH_SUGGESTION : BUTTON_KINDS.UNSET;

    const suggestionName = highlightString
        ? highlightSuggestions(data.name, highlightString)
        : data.name;

    const storeAnalytics = {
        eventProps: {
            searchVersion: AMPLITUDE_SEARCH_VERSIONS.V3,
            searchTerm: searchTerm,
            httpCorrelationId,
            resultsCount,
        },
        eventName: EVENTS.SHOP_DIRECTORY_SEARCH_TERM_CLICK,
        uiContext: UI_CONTEXTS.SEARCH,
    };
    const categoryAnalytics = {
        eventProps: {
            searchVersion: AMPLITUDE_SEARCH_VERSIONS.V3,
            searchTerm: searchTerm,
            categoryId: data.id,
            categoryName: data.name,
        },
        eventName: EVENTS.SHOP_DIRECTORY_CATEGORY_LIST_CLICK,
        uiContext: UI_CONTEXTS.SEARCH_AUTO_COMPLETE_LIST,
    };

    return (
        <li className={styles.searchBarSuggestionOption} key={data.name}>
            <LinkButton
                url={searchUrlLink}
                label={suggestionName}
                kind={suggestionButtonKind}
                size={BUTTON_SIZES.UNSET}
                newTab={false}
                fluid={false}
                disabled={false}
                analytics={"merchantId" in data ? storeAnalytics : categoryAnalytics}
            />
        </li>
    );
};

interface CategoriesOptionListProps {
    categories: CategoryProps[];
    searchUrl: string;
    activeSuggestedResult: number;
    searchTerm: string;
    httpCorrelationId: string;
}
const CategoriesOptionList: React.FC<CategoriesOptionListProps> = ({
    categories,
    searchUrl,
    activeSuggestedResult,
    searchTerm,
    httpCorrelationId,
}) => {
    const { t } = useTranslation();
    const listCategoriesHeadingText = t("search.listCategoriesHeading"),
        listNoCategoriesText = t("search.listNoCategoriesText");

    const categoriesSearchResults =
        categories.length > 0 ? (
            categories.map((category, index) => (
                <SuggestionList
                    key={category.id}
                    data={category}
                    searchUrlLink={`${searchUrl}/${category.slug}`}
                    searchTerm={searchTerm}
                    activeSuggestedResult={activeSuggestedResult}
                    index={index + CATEGORY_SUGGESTION_START_INDEX}
                    httpCorrelationId={httpCorrelationId}
                    resultsCount={categories.length}
                />
            ))
        ) : (
            <OptionListNoResults noResultsText={listNoCategoriesText} />
        );

    return (
        <>
            <SuggestionHeading text={listCategoriesHeadingText} />
            {categoriesSearchResults}
        </>
    );
};

interface QuerySuggestionsOptionListProps {
    suggestions: SuggestionProps[];
    searchUrl: string;
    highlightString: string;
    activeSuggestedResult: number;
    searchTerm: string;
    httpCorrelationId: string;
}
const QuerySuggestionsOptionList: React.FC<QuerySuggestionsOptionListProps> = ({
    suggestions,
    searchUrl,
    highlightString,
    activeSuggestedResult,
    searchTerm,
    httpCorrelationId,
}) => {
    const { t } = useTranslation();

    const listNoSuggestionsText = t("search.listNoSuggestionsText");

    const querySearchResults =
        suggestions.length > 0 ? (
            suggestions.slice(0, QUERY_SUGGESTIONS_RESULTS_LIMIT).map((phrase, index) => {
                return (
                    <SuggestionList
                        key={index}
                        data={phrase}
                        searchUrlLink={`${searchUrl}?search_version=${
                            SEARCH_VERSION.THREE
                        }&q=${encodeURIComponent(phrase.query_suggestion)}`}
                        highlightString={highlightString}
                        activeSuggestedResult={activeSuggestedResult}
                        index={index}
                        httpCorrelationId={httpCorrelationId}
                        resultsCount={suggestions.length}
                        searchTerm={searchTerm}
                    />
                );
            })
        ) : (
            <OptionListNoResults noResultsText={listNoSuggestionsText} />
        );

    return <>{querySearchResults}</>;
};

const SearchBar: React.FC = () => {
    const locale = useCurrentLocale();

    const router = useRouter();
    const { t } = useTranslation();

    const [{ searchTerm, httpCorrelationId }, setSearchData] = useDebounce(
        { searchTerm: "", httpCorrelationId: uuidV4() },
        100
    );

    const [suggestedSuggestions, setSuggestedSuggestions] = useState([]);
    const [showSuggestionList, setShowSuggestionList] = useState(false);
    const [suggestedCategories, setSuggestedCategories] = useState([]);
    const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);
    const [activeSuggestedResult, setActiveSuggestedResult] = useState(-1);

    const storeSearchUrl = `/${locale}/stores/search`;
    const categorySearchUrl = `/${locale}/categories`;

    useEffect(() => {
        Cookies.set(SEARCH_PAGE_COR_ID_COOKIE_KEY, httpCorrelationId);
    }, [httpCorrelationId]);

    useEffect(() => {
        Promise.all([
            fetchCategories(locale),
            fetchSuggestions(searchTerm, locale, httpCorrelationId),
        ]).then(([categories, suggestions]) => {
            setSuggestedCategories(categories);
            setSuggestedSuggestions(suggestions);
            setIsLoadingSuggestions(false);
        });
    }, [searchTerm]);

    if (LOCALES_WITH_NO_SEARCH.includes(locale)) {
        return null;
    }

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newSearchTerm = e.currentTarget.value;
        setSearchData({ searchTerm: newSearchTerm, httpCorrelationId: uuidV4() });
        setShowSuggestionList(!!newSearchTerm);

        if (newSearchTerm) {
            setIsLoadingSuggestions(true);
        }
    };

    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        switch (e.key) {
            case "Enter": {
                if (activeSuggestedResult >= CATEGORY_SUGGESTION_START_INDEX) {
                    const highlightedIndex =
                        activeSuggestedResult - CATEGORY_SUGGESTION_START_INDEX;
                    return router.push(`/categories/${suggestedCategories[highlightedIndex].slug}`);
                }

                const searchQuery = suggestedSuggestions[activeSuggestedResult]
                    ? suggestedSuggestions[activeSuggestedResult].name
                    : e.currentTarget.value;

                return router.push(
                    `/stores/search?search_version=${SEARCH_VERSION.THREE}&q=${encodeURIComponent(
                        searchQuery
                    )}`
                );
            }
            case "ArrowUp": {
                if (activeSuggestedResult === 0) {
                    return;
                }
                return setActiveSuggestedResult(activeSuggestedResult - 1);
            }
            case "ArrowDown": {
                const suggestionsLen = suggestedSuggestions.length;
                if (activeSuggestedResult - 1 === suggestionsLen) {
                    return;
                }
                return setActiveSuggestedResult(activeSuggestedResult + 1);
            }
        }
    };

    const suggestionListClassName = cx({
        [styles.searchBarSuggestionWrapper]: true,
        [styles.showSuggestionList]: showSuggestionList,
    });

    const suggestionsListContent = isLoadingSuggestions ? (
        <div className={styles.loadingSpinnerContainer}>
            <Lottie
                animationData={LogoAnimation}
                rendererSettings={{
                    preserveAspectRatio: "xMidYMid slice",
                }}
                loop
                play
            />
        </div>
    ) : (
        <>
            <QuerySuggestionsOptionList
                suggestions={suggestedSuggestions}
                searchUrl={storeSearchUrl}
                highlightString={searchTerm}
                activeSuggestedResult={activeSuggestedResult}
                httpCorrelationId={httpCorrelationId}
                searchTerm={searchTerm}
            />
            <div className={styles.optionListDivider} />
            <CategoriesOptionList
                categories={suggestedCategories}
                searchUrl={categorySearchUrl}
                activeSuggestedResult={activeSuggestedResult}
                httpCorrelationId={httpCorrelationId}
                searchTerm={searchTerm}
            />
        </>
    );

    return (
        <div
            className={styles.searchBarWrapper}
            onFocus={() => {
                setShowSuggestionList(!!searchTerm);
            }}
            onBlur={(e: FocusEvent) => {
                if (!e.currentTarget.contains(e.relatedTarget as Node)) {
                    setShowSuggestionList(false);
                }
            }}
        >
            <SearchInput
                placeholder={t("search.inputPlaceholderText")}
                size={INPUT_SIZES.SMALL}
                onChange={onChange}
                onKeyDown={onKeyDown}
                type={TYPES.TEXT}
                httpCorrelationId={httpCorrelationId}
                searchVersion={AMPLITUDE_SEARCH_VERSIONS.V3}
            />
            <ul data-testid="search-suggestions" className={suggestionListClassName}>
                {suggestionsListContent}
            </ul>
        </div>
    );
};

export default SearchBar;
