import { Logo, LogoSizes } from "@afterpaytouch/core";
import React, { useMemo } from "react";

import { useCurrentLocale } from "@/context/LanguageContext";

import useViewport from "@/hooks/useViewport";

import { Locale } from "../../types/locale";

export const getLogo = (
    locale: Locale,
    size: LogoSizes = "XLarge"
): React.ReactElement<typeof Logo> => {
    switch (locale) {
        case "en-GB":
        case "fr-FR":
        case "es-ES":
        case "it-IT":
            return <Logo.Clearpay size={size} />;
        default:
            return <Logo.Afterpay size={size} />;
    }
};

export const ApLogo: React.FC = () => {
    const locale = useCurrentLocale();
    const { isDesktop, isTablet, hasWindow } = useViewport();

    const size = useMemo(
        () => (isDesktop ? "XLarge" : isTablet ? "Large" : "Medium"),
        [isDesktop, isTablet]
    );

    return hasWindow ? getLogo(locale, size) : null;
};
