import React from "react";

import { useErrorMessage } from "../../context/ErrorMessageContext";
import ErrorMessage from "../error-message/error-message";

export const ErrorMessages: React.FC = () => {
    const { errorMessages, setErrorMessages } = useErrorMessage();
    const handleClose = (key) => {
        const newMessages = { ...errorMessages };
        delete newMessages[key];
        setErrorMessages(newMessages);
    };

    return (
        <>
            {Object.entries(errorMessages).map(([key, value]) => {
                return <ErrorMessage key={key} message={value} onClose={() => handleClose(key)} />;
            })}
        </>
    );
};

export default ErrorMessages;
