import React from "react";

import ChevronDown from "../../../assets/icons/chevron-down.svg";
import ChevronUp from "../../../assets/icons/chevron-up.svg";
import styles from "../navigation.module.scss";

type Props = {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
};

export const AuthMenuArrow: React.FC<Props> = ({ isOpen }) => {
    return (
        <div className={styles.dropdownMenu}>
            <div className={styles.authDropdownContainer}>
                {isOpen ? <ChevronUp /> : <ChevronDown />}
            </div>
        </div>
    );
};
