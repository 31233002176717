import { LatLngLiteralProps } from "../../interfaces/locations/location-interface";
import { Locale } from "../../types/locale";
import { FRITES_LOCALES } from "../constants";

const rad = (x: number) => (x * Math.PI) / 180;

export const distanceBetweenPositions = (
    p1: LatLngLiteralProps,
    p2: LatLngLiteralProps
): number => {
    const R = 6371;
    const dLat = rad(p2.lat - p1.lat);
    const dLong = rad(p2.lng - p1.lng);

    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;

    return d;
};

export const getDefaultLocation = (locale: Locale): LatLngLiteralProps => {
    switch (locale) {
        case "en-AU":
            return { lat: -33.8688, lng: 151.2093 };
        case "en-NZ":
            return { lat: -40.9006, lng: 172.886 };
        case "en-GB":
            return { lat: 55.3781, lng: 3.436 };
        default:
            return { lat: 39.809734, lng: -98.55562 };
    }
};

export const isFrites = (locale: Locale): boolean => FRITES_LOCALES.includes(locale);
