import { Dropdown } from "@afterpaytouch/core";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { localeCountryCodeMap } from "@/translations/config";

import { UI_CONTEXTS } from "@/analytics/constants";
import EVENTS from "@/analytics/events";
import { trackUserEvent } from "@/analytics/tracking";

import { useAuthLogoffUser, useAuthUserData } from "@/context/AuthContext";
import { useCurrentLocale } from "@/context/LanguageContext";

import { MenuModal } from "@/components/modals/menu-modal/menu-modal";
import { NewUserModal } from "@/components/modals/new-user-modal/new-user-modal";

import useViewport from "@/hooks/useViewport";

import { DropdownItem, DropdownItemType } from "@/types/auth";
import { Locale } from "@/types/locale";

import { KINDS, SIZES } from "../../buttons/buttons.constants";
import LinkButton from "../../buttons/link-button";
import styles from "../navigation.module.scss";
import { getPortalURL } from "./auth";
import { dropdownItems } from "./auth-authenticated-dropdown-items";
import { AuthMenuArrow } from "./auth-menu-arrow";
import { UserInitials } from "./auth-user-initials";
import { AuthUserName } from "./auth-user-name";

export function menuLinkHREF(type: DropdownItemType, locale: Locale): string {
    // shop and logout are not accounted for because
    // they are not really links
    switch (type) {
        case "home":
        case "orders":
            return getPortalURL(locale);
        case "upcomingPayments":
            return `${getPortalURL(locale)}/payment-schedule`;
        case "account":
            return `${getPortalURL(locale)}/account`;
        case "help":
            switch (locale) {
                case "en-GB":
                    return `https://help.clearpay.co.uk/hc/en-gb`;
                case "it-IT":
                case "fr-FR":
                case "es-ES":
                    return `https://help.clearpay.com/hc/${locale.toLowerCase()}`;
                default:
                    return `https://help.afterpay.com/hc/${locale.toLowerCase()}`;
            }
        case "shop":
        case "logout":
            return "";
    }
}

export const AuthAuthenticated: React.FC<Record<string, never>> = () => {
    const { t } = useTranslation();
    const locale = useCurrentLocale();
    const router = useRouter();

    const [isOpen, setIsOpen] = useState(false);

    const { isMobile } = useViewport();
    const userData = useAuthUserData();
    const logoffUser = useAuthLogoffUser();

    const authClickHandler = () => {
        trackUserEvent(
            locale,
            router.asPath,
            UI_CONTEXTS.TOP_NAVIGATION,
            EVENTS.WEB_LOGGED_IN_HEADER_BUTTON_CLICK,
            {
                locale,
                country: localeCountryCodeMap[locale],
            }
        );
        return setIsOpen((currentIsOpen) => !currentIsOpen);
    };

    const doLogout = useCallback(() => {
        logoffUser();
        setIsOpen(false);
    }, [logoffUser]);

    const sendAnalytics = async (event: string, outboundLink: string) => {
        trackUserEvent(locale, router.asPath, UI_CONTEXTS.TOP_NAVIGATION, event, {
            locale,
            country: localeCountryCodeMap[locale],
            outboundLink,
        });
    };

    const renderDropdownMenuItem = (item: DropdownItem, i: number): React.ReactNode => {
        const href = menuLinkHREF(item.type, locale);
        const ddMenuItem = (
            <Dropdown.MenuItem
                onClick={() => {
                    if (item.type === "logout") {
                        doLogout();
                    }
                    sendAnalytics(item.analyticsEvent, href);
                }}
                value={item.type}
                key={`${item.type}-${i}`}
            >
                <LinkButton
                    size={SIZES.UNSET}
                    kind={KINDS.UNSET}
                    label={t(item.translationKey)}
                    labelElement={item.icon}
                />
            </Dropdown.MenuItem>
        );
        if (!href) {
            return ddMenuItem;
        }
        return (
            <Link href={href}>
                <a>{ddMenuItem}</a>
            </Link>
        );
    };

    return (
        <>
            <div className={styles.loggedInContainer} onClick={authClickHandler}>
                <UserInitials userData={userData} isMobile={isMobile} />
                <AuthUserName givenNames={userData.givenNames} />
                <AuthMenuArrow isOpen={isOpen} setIsOpen={setIsOpen} />
                {!isMobile && isOpen && (
                    <div
                        className={styles.authDropdownMenu}
                        onMouseLeave={() => {
                            setIsOpen(false);
                        }}
                    >
                        <Dropdown.Menu defaultValue="shop" hasHeightLimit={false}>
                            {dropdownItems.map((item, i) => {
                                return renderDropdownMenuItem(item, i);
                            })}
                        </Dropdown.Menu>
                    </div>
                )}
            </div>
            <MenuModal show={isMobile && isOpen} onClose={() => setIsOpen(false)} />
            <NewUserModal forceClose={isOpen} />
        </>
    );
};
