import { SHOP_FEED_API_HOST } from "@/shared/constants";

import { axios } from "@/utils/axios";
import { throwOrReturnDefault } from "@/utils/requests";

import { SuggestionProps, transformSuggestions } from "@/interfaces/stores-interface";

import { Locale } from "@/types/locale";

export const fetchSuggestions = async (
    query: string,
    locale: Locale,
    httpCorrelationId: string
): Promise<Array<SuggestionProps>> => {
    const queryString = `&q=${encodeURIComponent(
        query
    )}&cor_id=${httpCorrelationId}&publisher=AP-PLATFORM`;

    const querySuggestionsUrl = `${SHOP_FEED_API_HOST}/${locale}/v1/search/autocomplete?${queryString}`;
    try {
        const response = await axios.get(querySuggestionsUrl, {
            headers: { "REQUEST-SOURCE": "Web" },
        });
        return transformSuggestions(response.data.data);
    } catch (err) {
        return throwOrReturnDefault(err, []);
    }
};
