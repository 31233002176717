import Cookies from "js-cookie";
import React from "react";

import { hostForLocale } from "@/utils/locale";

import { languageNames, localeCountryNameMap } from "@/translations/config";

import { Locale } from "@/types/locale";

import styles from "./localeSwitcher.module.scss";

export type Props = {
    locales: Readonly<Array<Locale>>;
    currentLocale: Locale;
};
const LocaleSwitcher: React.FC<Props> = (props: Props) => {
    const { currentLocale } = props;
    const localeCountryNames = { ...localeCountryNameMap };
    delete localeCountryNames.global;
    delete localeCountryNames["fr-FR"];
    delete localeCountryNames["es-ES"];
    delete localeCountryNames["it-IT"];

    const handleLocaleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newLocale = e.target.value as Locale;

        const currentHost = hostForLocale(currentLocale);
        const newHost = hostForLocale(newLocale);

        if (currentHost === newHost) {
            Cookies.set("NEXT_LOCALE", newLocale);
        }

        window.location.href = `${newHost}/${newLocale}`;
    };

    const countryOptions = () => {
        return Object.entries(localeCountryNames).map(([locale, countryName], index) => (
            <option key={index} value={locale}>
                {countryName === "Canada"
                    ? `${countryName} - ${languageNames[locale]}`
                    : countryName}
            </option>
        ));
    };

    return (
        <div className="language-switcher" data-testid="language-switcher">
            <select
                onChange={handleLocaleChange}
                defaultValue={currentLocale}
                className={styles.localeSwitcherInput}
            >
                {countryOptions()}
            </select>
        </div>
    );
};

export default LocaleSwitcher;
