import React from "react";

import styles from "./search-bar.module.scss";

interface SuggestionHeadingProps {
    text: string;
}
export const SuggestionHeading: React.FC<SuggestionHeadingProps> = ({ text }) => (
    <h2 className={styles.searchBarSuggestionHeading}>{text}</h2>
);
