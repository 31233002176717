import { Icon } from "@afterpaytouch/core";

import EVENTS from "@/analytics/events";

import { DropdownItem } from "@/types/auth";

export const dropdownItems: Array<DropdownItem> = [
    {
        type: "home",
        icon: <Icon.Home size={"Small"} />,
        translationKey: "auth.dropdownItems.home",
        analyticsEvent: EVENTS.WEB_LOGGED_IN_HEADER_HOME,
    },
    {
        type: "upcomingPayments",
        icon: <Icon.Calendar size={"Small"} />,
        translationKey: "auth.dropdownItems.upcomingPayments",
        analyticsEvent: EVENTS.WEB_LOGGED_IN_HEADER_UPCOMING_PAYMENTS,
    },
    {
        type: "orders",
        icon: <Icon.Order size={"Small"} />,
        translationKey: "auth.dropdownItems.order",
        analyticsEvent: EVENTS.WEB_LOGGED_IN_HEADER_ORDERS,
    },
    {
        type: "help",
        icon: <Icon.Chatting size={"Small"} />,
        translationKey: "auth.dropdownItems.help",
        analyticsEvent: EVENTS.WEB_LOGGED_IN_HEADER_HELP,
    },
    {
        type: "account",
        icon: <Icon.Profile size={"Small"} />,
        translationKey: "auth.dropdownItems.profile",
        analyticsEvent: EVENTS.WEB_LOGGED_IN_HEADER_ACCOUNT,
    },
    {
        type: "shop",
        icon: <Icon.Shop size={"Small"} />,
        translationKey: "auth.dropdownItems.shop",
        analyticsEvent: null,
    },
    {
        type: "logout",
        icon: <Icon.Exit size={"Small"} />,
        translationKey: "auth.dropdownItems.logOut",
        analyticsEvent: EVENTS.WEB_LOGGED_IN_HEADER_LOG_OUT,
    },
];
