import React from "react";

import styles from "./search-bar.module.scss";

interface OptionListNoResultsProps {
    noResultsText: string;
}
export const OptionListNoResults: React.FC<OptionListNoResultsProps> = ({ noResultsText }) => {
    return <li className={styles.searchBarResultNotFound}>{noResultsText}</li>;
};
