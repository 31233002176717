import { BuilderComponent } from "@builder.io/react";
import { BuilderContent } from "@builder.io/sdk";
import React, { useState } from "react";

import { BuilderModel } from "../../builder/types";
import ErrorMessages from "../error-messages/error-messages";
import styles from "./messageBanner.module.scss";

type Props = {
    builderMessages: Array<BuilderContent>;
};
export const MessageBanners: React.FC<Props> = ({ builderMessages }) => {
    const [showMessageBannerList, setShowMessageBannerList] = useState(
        builderMessages?.map?.(() => true)
    );

    const messageBanners = builderMessages?.map?.((builderMessage, index) => {
        if (!showMessageBannerList[index]) {
            return null;
        }
        const showCurrentBanner = showMessageBannerList[index];
        return (
            <BuilderComponent
                key={builderMessage.id}
                model={BuilderModel.MESSAGE}
                content={builderMessage}
                data={{
                    showCurrentBanner,
                    hideCurrentBanner: () => {
                        setShowMessageBannerList(
                            showMessageBannerList
                                .slice(0, index)
                                .concat([false], showMessageBannerList.slice(index + 1))
                        );
                    },
                }}
            />
        );
    });

    return (
        <React.Fragment>
            <div className={styles.messageBannersContainerShadow}>
                {messageBanners}
                <ErrorMessages />
            </div>
            <div className={styles.messageBannersContainer}>
                {messageBanners}
                <ErrorMessages />
            </div>
        </React.Fragment>
    );
};

export default MessageBanners;
