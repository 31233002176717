export const SEARCH_VERSION = {
    THREE: "3",
};

export const CATEGORY_SUGGESTION_START_INDEX = 9;
export const QUERY_SUGGESTIONS_RESULTS_LIMIT = 3;

type NamedResults = Array<{ name: string }>;
export const filterByName = (results: NamedResults, userInput: string): NamedResults => {
    return results?.filter((obj) => obj?.name.toLowerCase().includes(userInput.toLowerCase()));
};
