import { useTranslation } from "next-i18next";
import React from "react";

import styles from "../navigation.module.scss";

type Props = { givenNames: string };

export const AuthUserName: React.FC<Props> = ({ givenNames }) => {
    const { t } = useTranslation();

    return (
        <div className={styles.userHelloMessage}>
            {t("auth.greeting")}, {givenNames}
        </div>
    );
};
