import { useRouter } from "next/router";
import { useMemo } from "react";

// A bug (https://github.com/vercel/next.js/issues/28031) Next.js router is
// causing the .query object from the `useRouter()` value to be empty when there
// are actual query parameters present in the URL, but _only_ on static pages
// for some reason.
//
// Therefore this hook works around that issue and just grabs the query
// paramater directly from the path.

export const useNextQueryParams = (): { [key: string]: string } => {
    const router = useRouter();

    const value = useMemo(() => {
        const queryParamsStr = router.asPath.split("?").slice(1).join("");
        const urlSearchParams = new URLSearchParams(queryParamsStr);

        const params = Object.fromEntries(urlSearchParams.entries());
        return params;
    }, [router.asPath]);

    return value;
};
