import { FC } from "react";

import { GenericNavItem } from "@/interfaces/navigation-interface";

import { SubNavigationItem } from "../sub-navigation/sub-navigation-item/sub-navigation-item";
import styles from "./new-sub-navigation.module.scss";

type Props = {
    subMenuItemChunks?: Array<Array<GenericNavItem>>;
    autoFocus?: boolean;
};

export const NewSubNavigation: FC<Props> = ({ subMenuItemChunks, autoFocus = true }) => {
    return (
        <div className={styles.container}>
            {subMenuItemChunks
                .filter((chunk) => chunk?.length > 0)
                .map((chunk: Array<GenericNavItem>, index: number) => (
                    <ul className={styles.subNavigation} key={index}>
                        {chunk &&
                            chunk.map((chunkItem: GenericNavItem, subIndex: number) =>
                                chunkItem.text === "Discover More in the App" ? null : (
                                    <SubNavigationItem
                                        autoFocus={autoFocus}
                                        isFirst={subIndex === 0 && index === 0}
                                        key={chunkItem.id}
                                        navigationItem={chunkItem}
                                    />
                                )
                            )}
                    </ul>
                ))}
        </div>
    );
};
