import React, { MouseEventHandler } from "react";

import { GenericNavItem } from "../../../interfaces/navigation-interface";
import styles from "../navigation.module.scss";
import { SubNavigationItem } from "./sub-navigation-item/sub-navigation-item";

export interface Props {
    subMenuItemChunks?: Array<Array<GenericNavItem>>;
    onMouseLeave?: MouseEventHandler;
}

export const SubNavigation: React.FC<Props> = (props: Props) => {
    const { onMouseLeave, subMenuItemChunks } = props;

    return (
        <div className={styles.subNavigationContainer} onMouseLeave={onMouseLeave}>
            {subMenuItemChunks
                .filter((chunk) => chunk?.length > 0)
                .map((chunk: Array<GenericNavItem>, index: number) => (
                    <ul className={styles.subNavigation} key={index}>
                        {chunk &&
                            chunk.map((chunkItem: GenericNavItem, subIndex: number) => (
                                <SubNavigationItem
                                    isFirst={subIndex === 0 && index === 0}
                                    key={chunkItem.id}
                                    navigationItem={chunkItem}
                                />
                            ))}
                    </ul>
                ))}
        </div>
    );
};
