import { AuthUserData } from "@/types/auth";

import styles from "../navigation.module.scss";

type Props = {
    userData: AuthUserData;
    isMobile: boolean;
};

export const UserInitials: React.FC<Props> = ({ userData, isMobile }) => {
    return (
        <div className={styles.userInitialsButton}>
            <div className={styles.userInitialsText}>
                {userData.givenNames.substring(0, 1)}
                {!isMobile && userData.surname.substring(0, 1)}
            </div>
        </div>
    );
};
